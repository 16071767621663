// extracted by mini-css-extract-plugin
export const test = "scratch-module--test--1PPGt";
export const socialLink = "scratch-module--socialLink--3ObxH";
export const hero_socials = "scratch-module--hero_socials--2SuFz";
export const featuredSong = "scratch-module--featuredSong--JdDJZ";
export const featuredSong_pyramid = "scratch-module--featuredSong_pyramid--2purx";
export const featuredSong_cover = "scratch-module--featuredSong_cover--1s6Ps";
export const featuredSong_title = "scratch-module--featuredSong_title--EtSmp";
export const featuredSong_meta = "scratch-module--featuredSong_meta--2t4XC";
export const featuredSong_player = "scratch-module--featuredSong_player--9iz-w";
export const player = "scratch-module--player--3LorI";
export const player_playButton = "scratch-module--player_playButton--3Z_Ty";
export const player_progressWrapper = "scratch-module--player_progressWrapper--1R5uq";
export const player_progress = "scratch-module--player_progress--2YcXV";
export const circle = "scratch-module--circle--HyDzq";
export const logo = "scratch-module--logo--1Qh5U";
export const background = "scratch-module--background--EYD4Z";
export const backgroundPlate = "scratch-module--backgroundPlate--SZOLI";
export const brobby = "scratch-module--brobby--3wFMO";
export const backgroundPlate__0 = "scratch-module--backgroundPlate__0--rAYu6";
export const backgroundPlate__1 = "scratch-module--backgroundPlate__1--3afmE";
export const backgroundPlate__2 = "scratch-module--backgroundPlate__2--1GqDL";
export const backgroundPlate__3 = "scratch-module--backgroundPlate__3--Ge-OT";
export const backgroundPlate__4 = "scratch-module--backgroundPlate__4--1NpK6";
export const backgroundPlate__5 = "scratch-module--backgroundPlate__5--1_jc6";
export const backgroundPlate__6 = "scratch-module--backgroundPlate__6--1CTix";
export const backgroundPlate__7 = "scratch-module--backgroundPlate__7--1smtj";
export const backgroundLayer = "scratch-module--backgroundLayer--30H6L";
export const backgroundLayer__00 = "scratch-module--backgroundLayer__00--WWAgZ";
export const spin_xy = "scratch-module--spin_xy--2SSMi";
export const backgroundLayer__01 = "scratch-module--backgroundLayer__01--1LnZ5";
export const backgroundLayer__02 = "scratch-module--backgroundLayer__02--1ReT3";
export const backgroundLayer__03 = "scratch-module--backgroundLayer__03--2tlnb";
export const backgroundLayer__04 = "scratch-module--backgroundLayer__04--TzNam";
export const cube = "scratch-module--cube--Zl17u";
export const spin = "scratch-module--spin--bqbu7";
export const cubeSide = "scratch-module--cubeSide--2DHvt";
export const cubeSide__xPos = "scratch-module--cubeSide__xPos--3-CUW";
export const cubeSide__xNeg = "scratch-module--cubeSide__xNeg--1pcTC";
export const cubeSide__yPos = "scratch-module--cubeSide__yPos--3PLkr";
export const cubeSide__yNeg = "scratch-module--cubeSide__yNeg--32PFP";
export const cubeSide__zPos = "scratch-module--cubeSide__zPos--3Z4-3";
export const cubeSide__zNeg = "scratch-module--cubeSide__zNeg--1wWXz";
export const hero = "scratch-module--hero--2J-g-";
export const hero_circle = "scratch-module--hero_circle--2SAdO";