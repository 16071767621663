import React, { useState, useEffect, createRef } from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Footer from '../components/footer'

import { imageUrlFor } from '../lib/image-url';

import { cn, buildImageObj } from '../lib/helpers';

import Logo from '../assets/logo.svg';
import PlayButton from '../assets/play-button.svg';
import PauseButton from '../assets/pause-button.svg';
import TwitterBrands from '../assets/twitter-brands.svg';
import InstagramBrands from '../assets/instagram-brands.svg';
import SoundcloudBrands from '../assets/soundcloud-brands.svg';

import * as scratchStyles from '../assets/styles/scratch.module.scss';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      featuredMusic {
        id
        type
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
        title
        _rawExcerpt
        mainFile {
          asset {
            url
          }
        }
      }
    }

    music: allSanityMusic(limit: 50, sort: { fields: [releaseDate], order: DESC }) {
      edges {
        node {
          id
          type
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          mainFile {
            asset {
              url
            }
          }
        }
      }
    }

    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      address1
      address2
      zipCode
      city
      country
    }
  }
`

// return (
//   <div className={styles.profileCard}>
//     <div className={styles.profileMediaThumb}>
//       {image && image.asset && (
//         <img
//           src={imageUrlFor(buildImageObj(image))
//             .width(600)
//             .height(600)
//             .fit('crop')
//             .url()}
//         />
//       )}
//     </div>
//     <h2 className={styles.headline}>{name}</h2>
//     {_rawBio && (
//       <div className={styles.bio}>
//         <BlockText blocks={_rawBio} />
//       </div>
//     )}
//   </div>
// )

let musicBusy = false;
let musicLoaded = false;
let musicFile;

// const loadSoundFile = (ctx, path) => {
//   return new Promise((resolve, reject) => {
//     let xhr = new XMLHttpRequest();
//     xhr.open("GET", path, true);
//     xhr.responseType = 'arraybuffer';
//     xhr.onload = () => ctx.decodeAudioData(xhr.response, resolve, reject);
//     xhr.onerror = reject;
//     xhr.send();
//   });
// }

// const initSound = () => {

//   let cfg = { master: 1, ui: 1, bg: 0.2 };
//   let audioConfigString = localStorage["RIUH_audio_config"];
//   try { cfg = JSON.parse(audioConfigString); } catch { }

//   const ctx = new AudioContext();
//   const master = ctx.createGain();
//   master.gain.setValueAtTime(cfg.master, 0);
//   master.connect(ctx.destination);
//   // master.gain.value = 10.;

//   const bgGain = ctx.createGain();
//   bgGain.gain.setValueAtTime(cfg.bg, 0);
//   bgGain.connect(master);
  
//   const uiGain = ctx.createGain();
//   uiGain.gain.setValueAtTime(cfg.ui, 0);
//   uiGain.connect(master);

//   const audio = {
//     ctx, master, bgGain, uiGain, cfg,
//     sfx: null,
//   };

//   return audio;
// };

// const playSFX = (sound, fx, loop=false, parent=false) => {
//   const { ctx, master } = sound;
  
  
//   const source = ctx.createBufferSource();
//   if (loop) {
//     source.loop = true;
//     if (Array.isArray(loop)) {
//       source.loopStart = loop[0];
//       source.loopEnd = loop[1];
//     }
//   }
//   source.buffer = fx;
//   source.connect(parent || master);
//   source.start(0);

//   return source;
// }

// const audio = initSound();

const IndexPage = props => {
  const { data, errors } = props

  const audioElem = createRef(null);
  const [ audioReady, setAudioReady ] = useState(false);
  const [ playbackState, setPlaybackState ] = useState('stopped');

  useEffect(() => {
    if (audioElem.current) {
      audioElem.current.addEventListener('canplay', e => {
        console.log('canplay', Date.now(), e);
        setAudioReady(true);
      });

      [ 'durationchange', 'ended', 'loadeddata', 'loadedmetadata', 'pause', 'play', 'playing', 'stalled', 'waiting']
        .forEach(eventName => {
          audioElem.current.addEventListener(eventName, e => {
            console.log(eventName, Date.now(), e);
          });
        })
    }
  })


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }


  const site = (data || {}).site
  const companyInfo = (data || {}).companyInfo
  const postNodes = []
  const musicNodes = (data || {}).music
    ? mapEdgesToNodes(data.music)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const { featuredMusic } = site;

  useEffect(() => {
    if (musicBusy || musicLoaded) return;

    // musicBusy = true;
    // loadSoundFile(audio.ctx, featuredMusic.mainFile.asset.url)
    //   .then((res) => {
    //     musicFile = res;
    //     musicBusy = false;
    //     musicLoaded = true;
    //   });

    // window.audio = audio;

  });

  const toggleMusic = () => {
    console.log(musicFile, musicLoaded, musicBusy);
    // playSFX(audio, musicFile);
    if (audioElem.current) {
      if (audioElem.current.paused) {
        audioElem.current.play();
        setPlaybackState("playing");
      } else {
        audioElem.current.pause();
        setPlaybackState("paused");
      }
    }
  };

  const Cube = () => (
    <div className={scratchStyles.cube} aria-hidden="true">
      <div className={cn(scratchStyles.cubeSide, scratchStyles.cubeSide__xPos)} />
      <div className={cn(scratchStyles.cubeSide, scratchStyles.cubeSide__xNeg)} />
      <div className={cn(scratchStyles.cubeSide, scratchStyles.cubeSide__yPos)} />
      <div className={cn(scratchStyles.cubeSide, scratchStyles.cubeSide__yNeg)} />
      <div className={cn(scratchStyles.cubeSide, scratchStyles.cubeSide__zPos)} />
      <div className={cn(scratchStyles.cubeSide, scratchStyles.cubeSide__zNeg)} />
    </div>
  );

  const BackgroundPlate = (props) => (
    <div className={cn(scratchStyles.backgroundPlate, props.className)}>
      {
        new Array(6).fill('').map((_, i) => (
          <div className={cn(scratchStyles.backgroundLayer, scratchStyles[`backgroundLayer__0${5 - i}`])} />
        ))
      }
    </div>
  );


  return (
    <>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <div className={scratchStyles.background}>
          {
            new Array(1).fill('').map((_, i) => (
              <BackgroundPlate className={scratchStyles[`backgroundPlate__${i}`]} />
            ))
          }
        </div>
        <header className={scratchStyles.hero}>
          <h1 hidden>Welcome to {site.title}</h1>
          
          <Logo className={scratchStyles.logo} />
          
          <ul className={scratchStyles.hero_socials}>
            <li>
              <a className={scratchStyles.socialLink} href="https://twitter.com/RiuhBass">
                <TwitterBrands />
              </a>
            </li>
            <li>
              <a className={scratchStyles.socialLink} href="https://instagram.com/RiuhBass">
                <InstagramBrands />
              </a>
            </li>
            <li>
              <a className={scratchStyles.socialLink} href="https://soundcloud.com/riuh-space">
                <SoundcloudBrands />
              </a>
            </li>
          </ul>
        </header>


        <article className={scratchStyles.featuredSong}>
          <img className={scratchStyles.featuredSong_pyramid} src="/pyramid.png" />
          <img
            className={scratchStyles.featuredSong_cover}
            src={imageUrlFor(buildImageObj(featuredMusic.mainImage))
              .width(600)
              .height(600)
              .fit('crop')
              .url()}
          />

          <h3 className={scratchStyles.featuredSong_title}>
            {featuredMusic.title}
          </h3>
          <p className={scratchStyles.featuredSong_meta}>
            { featuredMusic.type.toUpperCase() } ∙ 3:12 
          </p>

          <div className={cn(scratchStyles.player, scratchStyles.featuredSong_player)}>
            <button
              className={scratchStyles.player_playButton}
              onClick={toggleMusic}
            >
              { playbackState == 'playing' ? <PauseButton /> : <PlayButton /> }
            </button>
            <span className={scratchStyles.player_progressWrapper}>
              <audio src={featuredMusic.mainFile.asset.url} ref={audioElem} />
              <progress value="0" max="100" className={scratchStyles.player_progress} />
            </span>
          </div>
        </article>

        {/* {projectNodes && (
          <ProjectPreviewGrid
            title='Music'
            nodes={projectNodes}
            browseMoreHref='/projects/'
          />
        )}
        {postNodes && (
          <BlogPostPreviewGrid
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/blog/'
          />
        )} */}
      </Container>
      
      {/* <pre style={{ background: "#000000dd", padding: "2rem", zIndex: 10, position: 'relative' }}>
          { JSON.stringify(site, null, 2) }
          { JSON.stringify(musicNodes[0], null, 2) }
        </pre> */}
      <Footer companyInfo={companyInfo} />
    </>
  )
}

export default IndexPage
